  .dropdown {
    position: relative;
    background-color: teal;
    margin-right: 10px;
    margin-bottom: 80px;
    z-index: 10;
  }
  
  .menu {
    position: absolute;
    list-style-type: none;
    margin: 0px 0;
    padding: 0;
    border: 1px solid grey;
    width: 150px;
    z-index: 100;
  }
  
  .menu > li {
    margin: 0;
    background-color: white;
  }
  
  .menu > li:hover {
    background-color: lightgray;
  }
  
  .menu > li > button {
    width: 100%;
    height: 100%;
    text-align: left;
  
    background: none;
    color: inherit;
    border: none;
    padding: 5px;
    margin: 0;
    font: inherit;
    cursor: pointer;
  }