
.footer {
  width: 100%;
  height: 150px;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 10px;
  margin-top: 200px;
  
}

.associates {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.associates img {
  width: 150px;
  height: 60px;
  margin: 0 40px;
}

.footer p {
  color: black;
  font-size: 14px;
  margin-top: 20px;
}

@media only screen and (max-width: 600px) {
  .footer svg {
    font-size: 50px;
  }

  .associates img {
    width: 80px;
    height: 60px;
    margin: 0 5px;
  }

  .footer p {
    font-size: 12px;
  }
}
