.team-page {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .team-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .team-member {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1rem;
    width: 300px;
    height: 400px;
    text-align: center;
    border: 1px solid gray;
    background-color: white;
    border-radius: 10px;
    box-shadow: 2px 2px 5px gray;
  }
  
  .team-member img {
    width: 200px;
    height: 200px;
    border-radius: 20%;
    margin-top: 1rem;
  }
  
  .social-media {
    display: flex;
    justify-content: center;
  }
  
  .social-media a {
    font-size: 24px;
    margin: 0 10px;
    color: gray;
    text-decoration: none;
  }
  
  .social-media a:hover {
    color: black;
  }

  .about-us-container {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    width: 60%;
    margin: 50px auto;

  }
  
  .about-us-title {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .about-us-text {
    font-size: 18px;
    line-height: 1.5;
  }
  